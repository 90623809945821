<template>
  <div id="holll">
    <ejs-dialog
      ref="blacklistPopup"
      :header="`위약 상세 ${subtitle}`"
      :allowDragging="true"
      :showCloseIcon="true"
      width="490"
      :isModal="true"
      :close="onPopupClosed"
    >
      <div class="window blacklistInformation">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">위약 상세 정보</div>
                    </div>
                    <div class="header-right">
                      <ul class="header-button">
                        <li>
                          <erp-button
                              button-div="GET"
                              :is-icon-custom="true"
                              @click.native="viewHistory"
                          >
                            변경이력
                          </erp-button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field" v-show="blacklistInfo.penltyId">
                              <!-- 필수 : required -->
                              <div class="title required">위약번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                        v-model="blacklistInfo.penltyId"
                                        :readonly="true"
                                        @keydown.native="preventEnterEvent"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">위약종류</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="penltyKind"
                                        v-model="blacklistInfo.penltyKind"
                                        :dataSource="
                                          blacklistInfoOptions.penltyKind
                                        "
                                        :allowFiltering="false"
                                        v-on:keydown.native="preventEnterEvent"
                                        :fields="
                                          blacklistInfoOptions.penltyKindField
                                        "
                                        cssClass="body-data-dropdown"
                                      ></ejs-dropdownlist>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field occurDate">
                              <!-- 필수 : required -->
                              <div class="title required">발생일자</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group date">
                                    <div class="form">
                                      <input-date
                                        ref="occurDate"
                                        v-model="blacklistInfo.occurDate"
                                        type="body-data"
                                        :format="DATE_FORMAT_YYYY_MM_DD"
                                        v-on:keydown.native="preventEnterEvent"
                                      />
                                    </div>
                                  </li>
                                  <li
                                    class="item form-group text"
                                    v-if="blacklistInfo.occurDate"
                                  >
                                    (<span
                                      :style="{
                                        color: getDayOfWeekCaptionColor(
                                          new Date(blacklistInfo.occurDate)
                                        ),
                                      }"
                                      >{{
                                        getDayOfWeekCaption(
                                          new Date(blacklistInfo.occurDate)
                                        )
                                      }}</span
                                    >)
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field memberNameNumber">
                              <!-- 필수 : required -->
                              <div class="title required">회원명/번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group input">
                                    <div class="form">
                                      <input-text
                                        class="validate"
                                        id="memberName"
                                        ref="memberName"
                                        v-model="blacklistInfo.memberName"
                                        @keydown.native="preventEnterEvent"
                                        @change="onMemberNameChanged"
                                        @keydown.enter="memberNameInputKeyEnter"
                                      />
                                    </div>
                                  </li>
                                  <li class="item form-group text">
                                    {{ blacklistInfo.memberNo }}
                                  </li>
                                  <li class="item form-group button">
                                    <ul class="button">
                                      <li class="search">
                                        <erp-button
                                          button-div="GET"
                                          ref="memberPopupOpenButton"
                                          @click.native="
                                            onMemberPopupOpen(
                                              true,
                                              blacklistInfo.memberName
                                            )
                                          "
                                        >
                                          조회
                                        </erp-button>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">회원구분</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        id="memberDiv"
                                        v-model="blacklistInfo.memberDiv"
                                        :dataSource="
                                          blacklistInfoOptions.memberDiv
                                        "
                                        :allowFiltering="false"
                                        v-on:keydown.native="preventEnterEvent"
                                        :fields="
                                          blacklistInfoOptions.memberDivField
                                        "
                                        cssClass="body-data-dropdown"
                                        @change="onMemberDivChanged"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">회원등급</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        id="memberGrade"
                                        v-model="blacklistInfo.memberGrade"
                                        :dataSource="
                                          blacklistInfoOptions.memberGrade
                                        "
                                        :allowFiltering="false"
                                        v-on:keydown.native="preventEnterEvent"
                                        :fields="
                                          blacklistInfoOptions.memberGradeField
                                        "
                                        cssClass="body-data-dropdown"
                                        @change="onMemberGradeChanged"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field groupName">
                              <!-- 필수 : required -->
                              <div class="title">단체명</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group input">
                                    <div class="form">
                                      <input-text
                                        id="grpName"
                                        v-model="blacklistInfo.grpName"
                                        @keydown.native="preventEnterEvent"
                                        @change="onGrpNameChanged"
                                      />
                                    </div>
                                  </li>
                                  <li class="item form-group button">
                                    <ul class="button">
                                      <li class="search">
                                        <erp-button
                                          button-div="GET"
                                          @click.native="onGroupPopupOpen(true)"
                                        >
                                          조회
                                        </erp-button>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">연락처</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <component-telephone
                                        v-model="blacklistInfo.contactTel"
                                        :max-length="11"
                                        @keydown.native="preventEnterEvent"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field reservationDateTime">
                              <!-- 필수 : required -->
                              <div class="title">예약일자/시간</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group date">
                                    <div class="form">
                                      <input-date
                                        v-model="blacklistInfo.resveDate"
                                        type="body-data"
                                        :format="DATE_FORMAT_YYYY_MM_DD"
                                        v-on:keydown.native="preventEnterEvent"
                                        @change="onResveDateDateChange"
                                      />
                                    </div>
                                  </li>
                                  <li
                                    class="item form-group text"
                                    v-if="blacklistInfo.resveDate"
                                  >
                                    (<span
                                      :style="{
                                        color: getDayOfWeekCaptionColor(
                                          new Date(blacklistInfo.resveDate)
                                        ),
                                      }"
                                      >{{
                                        getDayOfWeekCaption(
                                          new Date(blacklistInfo.resveDate)
                                        )
                                      }}</span
                                    >)
                                  </li>
                                  <li class="item form-group time">
                                    <div class="form">
                                      <input-time
                                        v-model="blacklistInfo.resveTime"
                                        :format="'HH:mm'"
                                        v-on:keydown.native="preventEnterEvent"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">코스</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        id="resveCourse"
                                        v-model="blacklistInfo.resveCourse"
                                        :dataSource="
                                          blacklistInfoOptions.resveCourse
                                        "
                                        v-on:keydown.native="preventEnterEvent"
                                        :allowFiltering="false"
                                        :fields="
                                          blacklistInfoOptions.resveCourseField
                                        "
                                        cssClass="body-data-dropdown"
                                      ></ejs-dropdownlist>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">취소채널</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        id="cancelChannel"
                                        v-model="blacklistInfo.cancelChannel"
                                        :dataSource="
                                          blacklistInfoOptions.resveChannel
                                        "
                                        v-on:keydown.native="preventEnterEvent"
                                        :allowFiltering="false"
                                        :fields="
                                          blacklistInfoOptions.resveChannelField
                                        "
                                        cssClass="body-data-dropdown"
                                      ></ejs-dropdownlist>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field placeboCode">
                              <!-- 필수 : required -->
                              <div class="title required">위약구분</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="penltyDiv"
                                        v-model="blacklistInfo.penltyDiv"
                                        :dataSource="
                                          blacklistInfoOptions.penltyDiv
                                        "
                                        v-on:keydown.native="preventEnterEvent"
                                        :allowFiltering="false"
                                        :fields="
                                          blacklistInfoOptions.penltyDivField
                                        "
                                        cssClass="body-data-dropdown"
                                        @change="onPenltyDivChanged"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field placeboCode">
                              <!-- 필수 : required -->
                              <div class="title required">위약코드</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group week">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="bsnCode"
                                        v-model="blacklistInfo.bsnCode"
                                        :dataSource="
                                          blacklistInfoOptions.bsnCode
                                        "
                                        v-on:keydown.native="preventEnterEvent"
                                        :allowFiltering="false"
                                        :fields="
                                          blacklistInfoOptions.bsnCodeField
                                        "
                                        cssClass="body-data-dropdown"
                                        @change="onChangeBsnCode"
                                      ></ejs-dropdownlist>
                                    </div>
                                  </li>
                                  <li class="item form-group code">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="penltyCode"
                                        v-model="blacklistInfo.penltyCode"
                                        :dataSource="
                                          blacklistInfoOptions.penltyCode
                                        "
                                        v-on:keydown.native="preventEnterEvent"
                                        :allowFiltering="false"
                                        :fields="
                                          blacklistInfoOptions.penltyCodeField
                                        "
                                        cssClass="body-data-dropdown"
                                        @change="onPenltyCodeChanged"
                                      ></ejs-dropdownlist>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">위약제한</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="penltyLmttScope"
                                        v-model="blacklistInfo.penltyLmttScope"
                                        :dataSource="
                                          blacklistInfoOptions.penltyLmttScope
                                        "
                                        v-on:keydown.native="preventEnterEvent"
                                        :allowFiltering="false"
                                        :fields="
                                          blacklistInfoOptions.penltyLmttScopeField
                                        "
                                        cssClass="body-data-dropdown"
                                      ></ejs-dropdownlist>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field sanctionPeriod">
                              <!-- 필수 : required -->
                              <div class="title required">제재기간</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group dateRange">
                                    <div class="form">
                                      <input-date-range
                                        ref="punishDate"
                                        v-model="punishDate"
                                        type="body-data"
                                      />
                                    </div>
                                  </li>
                                  <li
                                    class="item form-group text"
                                    v-if="penltyDayNum"
                                  >
                                    {{ penltyDayNum }}일
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">차감방법</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="ddctMethod"
                                        v-model="blacklistInfo.ddctMethod"
                                        :dataSource="
                                          blacklistInfoOptions.ddctMethod
                                        "
                                        v-on:keydown.native="preventEnterEvent"
                                        :allowFiltering="false"
                                        :fields="
                                          blacklistInfoOptions.ddctMethodField
                                        "
                                        cssClass="body-data-dropdown"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">차감점수</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-number
                                        id="ddctScore"
                                        v-model="blacklistInfo.ddctScore"
                                        :allowDot="false"
                                        :allowMinus="false"
                                        :propMaxLength="9"
                                        v-on:keydown.native="preventEnterEvent"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">벌점</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-number
                                        id="penltyDem"
                                        v-model="blacklistInfo.penltyDem"
                                        :allowDot="false"
                                        :allowMinus="false"
                                        :propMaxLength="9"
                                        v-on:keydown.native="preventEnterEvent"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">위약금(%)</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-number
                                        id="penltyAmt"
                                        v-model="blacklistInfo.penltyAmt"
                                        v-on:keydown.native="preventEnterEvent"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">납입방법</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        id="payMethod"
                                        v-model="blacklistInfo.payMethod"
                                        :dataSource="
                                          blacklistInfoOptions.payMethod
                                        "
                                        v-on:keydown.native="preventEnterEvent"
                                        :allowFiltering="false"
                                        :fields="
                                          blacklistInfoOptions.payMethodField
                                        "
                                        cssClass="body-data-dropdown"
                                      ></ejs-dropdownlist>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">납입일자</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-date
                                        v-model="blacklistInfo.payDate"
                                        type="body-data"
                                        :format="DATE_FORMAT_YYYY_MM_DD"
                                        v-on:keydown.native="preventEnterEvent"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field remarks">
                              <!-- 필수 : required -->
                              <div class="title">위약사유</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-textarea
                                        v-model="blacklistInfo.penltyResn"
                                        @keydown.native="preventEnterEvent"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field" v-show="blacklistInfo.penltyId">
                              <!-- 필수 : required -->
                              <div class="title">등록자/일시</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group text">
                                    {{ blacklistInfo.insertName }} /
                                    {{ blacklistInfo.insertDt }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  button-div="SAVE"
                  :ignore="isPopupOpened"
                  :is-shortcut-button="true"
                  :is-key-color="true"
                  v-on:click.native="saveBlackinfo"
              >
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="popupClose">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <member-select-popup
      v-if="isMemberSelectPopupOpen"
      ref="memberSelectPopup"
      @popupClosed="memberSelectPopupClosed"
      @popupConfirmed="memberSelectPopupConfirmed"
    />
    <group-popup
      v-if="isGroupPopupOpen"
      ref="groupPopup"
      @popupClosed="onGroupPopupClosed"
    />
    <history-popup
      v-if="isHistoryPopupOpen"
      ref="historyPopup"
      @popupClosed="historyPopupClosed"
    />
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import {
  commonCodesGetCommonCode,
  commonCodesGetComName,
  commonCodeGetComCodeDefaultValue,
} from "@/utils/commonCodes";
import { getTodayNavigationDate, getDayOfWeekCaptionColor, getDayOfWeekCaption } from "@/utils/date";
import memberSelectPopup from "@/views/common/MemberSelectPopup";
import groupPopup from "./GroupPopup";
import InputDate from "@/components/common/datetime/InputDate";
import InputTime from "@/components/common/datetime/InputTime";
import InputDateRange from "@/components/common/datetime/InputDateRange";
import { formPreventEnterEvent, validateFormRefs } from "@/utils/formUtil";
import ErpButton from "@/components/button/ErpButton.vue";

import moment from "moment";

import {
  getBlackBsnCode,
  getBlackEnddate,
  postBlackinfo,
  putBlackinfo,
} from "@/api/blacklist";
import { getGroupList } from "@/api/group";
import { DATE_FORMAT_YYYY_MM_DD } from "@/utils/date";
import InputNumber from "@/components/common/InputNumber";
import InputText from "@/components/common/text/InputText";
import InputTextarea from "@/components/common/text/InputTextarea";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ComponentTelephone from "@/components/ComponentTelephone";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import HistoryPopup from "../../common/HistoryPopup";

export default {
  name: "BlacklistRegistrationPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    ComponentTelephone,
    InputTextarea,
    InputText,
    InputNumber,
    memberSelectPopup,
    groupPopup,
    InputDate,
    InputTime,
    InputDateRange,
    HistoryPopup,
    ErpButton,
  },
  computed: {
    isPopupOpened() {
      return (
        this.isMemberSelectPopupOpen ||
        this.isGroupPopupOpen ||
        this.isHistoryPopupOpen
      );
    },
    subtitle() {
      if (this.blacklistInfo.penltyId) {
        return "수정";
      } else {
        return "등록";
      }
    },
    punishDate: {
      get: function () {
        return {
          from: this.blacklistInfo.punshStartDate,
          to: this.blacklistInfo.punshEndDate,
        };
      },
      set: function (punishDate) {
        this.blacklistInfo.punshStartDate = punishDate.from;
        this.blacklistInfo.punshEndDate = punishDate.to;
      },
    },
    penltyDayNum() {
      if (
        !this.blacklistInfo.punshStartDate ||
        !this.blacklistInfo.punshEndDate
      ) {
        return null;
      }
      return (
        moment
          .duration(
            new Date(this.blacklistInfo.punshEndDate) -
              new Date(this.blacklistInfo.punshStartDate)
          )
          .asDays() + 1
      ).toString();
    },
  },
  data: function () {
    return {
      DATE_FORMAT_YYYY_MM_DD,
      isHistoryPopupOpen: false,
      // validation 할 ref 리스트
      validateRefList: {
        // 위약 종류
        penltyKind: { required: true },
        // 발생 일자
        occurDate: { required: true },
        // 멤버명
        memberName: { required: true },
        // 주중/주말
        bsnCode: { required: true },
        // 위약 코드
        penltyCode: { required: true },
        // 위약 구분
        penltyDiv: { required: true },
        // 위약 제한 코드
        penltyLmttScope: { required: true },
        // 제재기간
        punishDate: { required: true }
      },
      isMemberSelectPopupOpen: false,
      isGroupPopupOpen: false,
      blacklistInfo: {
        bsnCode: null, // 추가 시 예약일자/시간 변경 시 위약코드 값이 나타나지 않음
        occurDate: null,
        memberNo: null,
        memberDiv: null,
        memberGrade: null,
        membershipId: null,
        grpName: null,
        grpNo: null,
        penltyDayNum: null,
        punshStartDate: null,
        punshEndDate: null,
        penltyDem: null,
        penltyLmttScope: null,
        penltyAmt: null,
        penltyKind: null,
        penltyCode: null,
        penltyDiv: null,
      },
      blacklistInfoOptions: {
        strictMode: {},
        penltyKind: commonCodesGetCommonCode("PENLTY_KIND"),
        penltyKindField: { text: "comName", value: "comCode" },
        memberDiv: commonCodesGetCommonCode("MEMBER_DIV"),
        memberDivField: { text: "comName", value: "comCode" },
        memberGrade: commonCodesGetCommonCode("MEMBER_GRADE"),
        memberGradeField: { text: "comName", value: "comCode" },
        resveCourse: commonCodesGetCommonCode("COURSE_CODE"),
        resveCourseField: { text: "comName", value: "comCode" },
        resveChannel: commonCodesGetCommonCode("RESVE_CHANNEL"),
        resveChannelField: { text: "comName", value: "comCode" },
        penltyCode: commonCodesGetCommonCode("PENLTY_CODE"),
        penltyCodeField: { text: "comName", value: "comCode" },
        penltyDiv: commonCodesGetCommonCode("PENLTY_DIV"),
        penltyDivField: { text: "comName", value: "comCode" },
        bsnCode: commonCodesGetCommonCode("BSN_CODE"),
        bsnCodeField: { text: "comName", value: "comCode" },
        penltyLmttScope: commonCodesGetCommonCode("PENLTY_LMTT_SCOPE"),
        penltyLmttScopeField: { text: "comName", value: "comCode" },
        payMethod: commonCodesGetCommonCode("PAY_METHOD"),
        payMethodField: { text: "comName", value: "comCode" },
        ddctMethod: commonCodesGetCommonCode("DDCT_METHOD"),
        ddctMethodField: { text: "comName", value: "comCode" },
      },
    };
  },
  async mounted() {
    // bsnCode에서 휴장(CLOSE) 제외
    this.blacklistInfoOptions.bsnCode.splice(
      this.blacklistInfoOptions.bsnCode.findIndex(
        (code) => code.comCode === "CLOSE"
      ),
      1
    );

    if (
      this.blacklistInfoOptions.ddctMethod.findIndex(
        (obj) => obj.comName === ""
      ) === -1
    ) {
      this.blacklistInfoOptions.ddctMethod.unshift({
        comCode: null,
        comName: "",
        codeAbrv: "",
        defaultFlag: false,
      });
    }

    await this.$nextTick();
    setTimeout(() => {
      // TODO : 변경이력 버튼으로 포커싱되는 버그 임시 처리
      this.$refs['memberName'].focusIn();
    }, 500);
  },
  methods: {
    validateFormRefs,
    preventEnterEvent: formPreventEnterEvent,
    commonCodesGetComName: commonCodesGetComName,
    getDayOfWeekCaptionColor: getDayOfWeekCaptionColor,
    getDayOfWeekCaption: getDayOfWeekCaption,
    async openPopup(blacklistData) {
      if (blacklistData) {
        console.log('blacklistData.===>', blacklistData);
        // 마스킹 된 데이터는 보여주지 않음
        if (!(blacklistData.contactTel?.indexOf("*") < 0)) {
          delete blacklistData.contactTel;
        }

        this.blacklistInfo = blacklistData;
      } else {
        this.blacklistInfo.penltyKind = commonCodeGetComCodeDefaultValue(
          "PENLTY_KIND"
        );
        // this.blacklistInfo.bsnCode = commonCodeGetComCodeDefaultValue(
        //   "BSN_CODE"
        // );
        // this.blacklistInfo.penltyCode = commonCodeGetComCodeDefaultValue(
        //   "PENLTY_CODE"
        // );
        this.blacklistInfo.penltyLmttScope = commonCodeGetComCodeDefaultValue(
          "PENLTY_LMTT_SCOPE"
        );
        const now = await getTodayNavigationDate(DATE_FORMAT_YYYY_MM_DD);
        this.blacklistInfo.occurDate = now;
        this.punishDate = { from: now, to: now };
      }
    },
    onPopupClosed() {
      this.$emit("popupClosed");
    },
    // TODO : strict mode 설정 시 chnage 이벤트가 2번 이상 발생하는 버그 있음. 현재 Syncfusion에서 수정 중
    async onResveDateDateChange(args) {
      if (!args.data) {
        return;
      }
      // if (args.isInteracted) { // 키보드 또는 마우스로 입력했을때에만 TODO : 현재 위의 버그때문에 문제가 있으므로 체크하는 부분을 주석처리함. 버그 수정 후 다시 살려야 함
      const { value } = await getBlackBsnCode(this.blacklistInfo.resveDate);
      this.blacklistInfo.bsnCode = value;
    },
    // onPunshStartDateChange (args) {
    //   // TODO : strict mode 설정 시 chnage 이벤트가 2번 이상 발생하는 버그 있음. 현재 Syncfusion에서 수정 중
    //   if (args.value !== null) {
    //     this.blacklistInfo.punshStartDate = getFormattedDateTimePicker(args, 'yyyy-MM-dd');
    //
    //     // if (args.isInteracted) { // 키보드 또는 마우스로 입력했을때에만 TODO : 현재 위의 버그때문에 문제가 있으므로 체크하는 부분을 주석처리함. 버그 수정 후 다시 살려야 함
    //     this.getBlackEnddate();
    //     // }
    //   } else {
    //     this.blacklistInfo.punshStartDate = null;
    //   }
    // },
    // onPunshEndDateChange (args) {
    //   if (args.value !== null) {
    //     this.blacklistInfo.punshEndDate = getFormattedDateTimePicker(args, 'yyyy-MM-dd');
    //     this.blacklistInfo.penltyDayNum = moment.duration(new Date(this.blacklistInfo.punshEndDate) - new Date(this.blacklistInfo.punshStartDate)).asDays();
    //   } else {
    //     this.blacklistInfo.punshEndDate = null;
    //   }
    // },
    async onMemberNameChanged(args) {
      if (args.event && args.previousValue !== args.value) {
        if (args.value.trim() === "") {
          // 빈 칸인 경우에는 미 설정으로
          this.setNoMember();
          return;
        }

        // 회원정보에 없는 비회원이라도 무조건 회원 검색 팝업 호출. 모든 화면 공통 조건.
        this.onMemberPopupOpen(false, args.value);
      }
    },
    async onGrpNameChanged(args) {
      if (args.event && args.previousValue !== args.value) {
        if (args.value.trim() === "") {
          this.clearGrpInfo();
          return;
        }
        const { value: groupValue } = await getGroupList({
          searchValue: args.value,
        });
        const groupList = groupValue.groupInfoList;

        if (groupList && groupList.length > 1) {
          this.onGroupPopupOpen();
        } else if (groupList && groupList.length === 1) {
          // 그룹정보 설정
          this.setGrpInfos(groupList[0]);
        } else {
          // 그룹정보 clear
          this.clearGrpInfo();
        }
      } else if(!args.previousValue && !args.value) {
        this.clearGrpInfo();
      }
    },
    onGroupPopupOpen(isOpenedWithButton = false) {
      this.isGroupPopupOpen = true;

      const groupPopupData = {
        grpName: this.blacklistInfo.grpName ? this.blacklistInfo.grpName : null,
        isOpenedWithButton: isOpenedWithButton,
      };
      this.$nextTick(() => {
        this.$refs.groupPopup.openPopup(groupPopupData);
      });
    },
    setGrpInfos(grpInfo) {

      this.blacklistInfo.grpName = grpInfo.grpName;
      this.blacklistInfo.grpNo = grpInfo.grpNo;
      this.blacklistInfo.grpKind = grpInfo.grpKind;
      this.blacklistInfo.memberName = grpInfo.generName;
      this.blacklistInfo.memberNo = grpInfo.generMemberNo;
      this.blacklistInfo.membershipId = grpInfo.generMembershipId;
      this.blacklistInfo.contactTel = grpInfo.generContactTel;


      if(grpInfo.generMemberDiv != ""){
        this.blacklistInfo.memberDiv = grpInfo.generMemberDiv;
      }else {
        this.blacklistInfo.memberDiv = "NOM";
      }
      if(grpInfo.generMemberGrade != ""){
        this.blacklistInfo.memberGrade = grpInfo.generMemberGrade;
      }else {
        this.blacklistInfo.memberGrade = commonCodeGetComCodeDefaultValue("MEMBER_GRADE");
      }

    },
    clearGrpInfo() {
      this.blacklistInfo.grpName = null;
      this.blacklistInfo.grpNo = null;
      this.blacklistInfo.grpKind = 'FIT';
    },
    onMemberPopupOpen(
      isOpenedWithButton,
      memberNameNo,
      memberDivChanged = false,
      memberGradeChanged = false,
    ) {
      this.isMemberSelectPopupOpen = true;

      const memberData = {
        memberNameNo: memberNameNo,
        isOpenedWithButton: isOpenedWithButton,
        memberDivChanged: memberDivChanged,
        memberGradeChanged: memberGradeChanged,
      };
      this.$nextTick(() => {
        this.$refs.memberSelectPopup.showPopup(memberData);
      });
    },
    setMemberInfos(memberInfo) {
      this.blacklistInfo.memberName = memberInfo.memberName;
      this.blacklistInfo.memberNo = memberInfo.memberNo;
      this.blacklistInfo.memberDiv = memberInfo.memberDiv;
      this.blacklistInfo.memberGrade = memberInfo.memberGrade;
      this.blacklistInfo.membershipId = memberInfo.membershipId;
      this.blacklistInfo.contactTel = memberInfo.hpNo;

      //단체초기화
      this.clearGrpInfo();
    },
    setNoMember() {
      this.blacklistInfo.memberNo = null;
      this.blacklistInfo.memberDiv = "NOM";
      this.blacklistInfo.memberGrade = commonCodeGetComCodeDefaultValue("MEMBER_GRADE");
      this.blacklistInfo.membershipId = null;

      //단체초기화
      this.clearGrpInfo();
    },
    onGroupPopupClosed(event) {
      this.isGroupPopupOpen = false;

      if (event.popupData.isOpenedWithButton) {
        // 단체검색으로 팝업을 Open 한 경우 : 검색하여 선택한 경우에만 처리함
        if (event.selectedGroup) {
          this.setGrpInfos(event.selectedGroup);
        }
      } else {
        // 단체 text 창 입력으로 팝업을 Open 한 경우
        if (event.selectedGroup) {
          this.setGrpInfos(event.selectedGroup);
        } else {
          this.clearGrpInfo();
        }
      }
    },
    memberSelectPopupClosed(data) {
      this.isMemberSelectPopupOpen = false;
      // 회원구분 변경시 동명이인 회원 검색 팝업 호출후 팝업 그냥 닫으면
      // 회원구분 변경한 값은 그대로 유지.
      if (!data.popupData.memberDivChanged) {
        if (!data.popupData.isOpenedWithButton) {
          // textbox를 통해 팝업을 띄웠는데, 선택하지 않고 닫은 경우
          this.setNoMember();
        }
      } else if (!data.popupData.memberGradeChanged) {
        // 회원등급 변경시 동명이인 회원 검색 팝업 호출후 팝업 그냥 닫으면
        // 회원등급 변경한 값은 그대로 유지.
        // MEMBERSHIP_ID, MEMBER_NO, MEMBER_DIV = 기존값 그대로 유지.
        // MEMBER_GRADE = 변경한 값
        if (!data.popupData.isOpenedWithButton) {
          // textbox를 통해 팝업을 띄웠는데, 선택하지 않고 닫은 경우
          this.setNoMember();
        }
      }
    },
    async memberSelectPopupConfirmed(data) {
      this.isMemberSelectPopupOpen = false;
      if (data.selectedRowData) {
        this.setMemberInfos(data.selectedRowData);
      }
      await this.$nextTick();
      this.$refs.bsnCode.focusIn();
    },
    viewHistory() {
      // alert("변경이력 개발중입니다");
      if (!this.blacklistInfo.penltyId) {
        return;
      }

      GolfErpAPI.fetchReservationPenaltyHistory(this.blacklistInfo.penltyId).then((rawHistories) => {
        console.log('rawHistories.===>', rawHistories);
        this.isHistoryPopupOpen = true;
        this.$nextTick(() => {
          this.$refs.historyPopup.show({
            rawHistories,
            fields: {
              ReservationPenalty: {
                __name__: "위약/블랙리스트정보",
                penltyKind: {
                  name: "위약종류",
                  type: "commonCode",
                  groupCode: "PENLTY_KIND",
                  methods: ["create", "update", "delete"],
                },
                occurDate: {
                  name: "발생일자",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                memberName: {
                  name: "회원명",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                membershipId: {
                  name: "회원권ID",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                memberNo: {
                  name: "회원번호",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                memberDiv: {
                  name: "회원구분",
                  type: "commonCode",
                  groupCode: "MEMBER_DIV",
                  methods: ["create", "update", "delete"],
                },
                grpNo: {
                  name: "단체번호",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                grpKind: {
                  name: "단체종류",
                  type: "commonCode",
                  groupCode: "GRP_KIND",
                  methods: ["create", "update", "delete"],
                },
                grpName: {
                  name: "단체명",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                contactTel: {
                  name: "연락처",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                resveId: {
                  name: "예약ID",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                resveDate: {
                  name: "예약일자",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                resveCourse: {
                  name: "예약코스",
                  type: "commonCode",
                  groupCode: "RESVE_COURSE",
                  methods: ["create", "update", "delete"],
                },
                resveTime: {
                  name: "예약시간",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                cancelChannel: {
                  name: "취소채널",
                  type: "commonCode",
                  groupCode: "RESVE_CHANNEL",
                  methods: ["create", "update", "delete"],
                },
                bsnCode: {
                  name: "영업코드",
                  type: "commonCode",
                  groupCode: "BSN_CODE",
                  methods: ["create", "update", "delete"],
                },
                penltyCode: {
                  name: "위약코드",
                  type: "commonCode",
                  groupCode: "PENLTY_CODE",
                  methods: ["create", "update", "delete"],
                },
                penltyDiv: {
                  name: "위약구분",
                  type: "commonCode",
                  groupCode: "PENLTY_DIV",
                  methods: ["create", "update", "delete"],
                },
                penltyLmttScope: {
                  name: "위약제한범위",
                  type: "commonCode",
                  groupCode: "PENLTY_LMTT_SCOPE",
                  methods: ["create", "update", "delete"],
                },
                punshStartDate: {
                  name: "제재시작일자",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                punshEndDate: {
                  name: "제재종료일자",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                ddctMethod: {
                  name: "차감방법",
                  type: "commonCode",
                  groupCode: "DDCT_METHOD",
                  methods: ["create", "update", "delete"],
                },
                ddctScore: {
                  name: "차감점수",
                  type: "number",
                  methods: ["create", "update", "delete"],
                },
                penltyDem: {
                  name: "위약벌점",
                  type: "number",
                  methods: ["create", "update", "delete"],
                },
                penltyAmt: {
                  name: "위약금",
                  type: "number",
                  methods: ["create", "update", "delete"],
                },
                payMethod: {
                  name: "납입방법",
                  type: "commonCode",
                  groupCode: "PAY_METHOD",
                  methods: ["create", "update", "delete"],
                },
                payDate: {
                  name: "납입일자",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                penltyResn: {
                  name: "위약사유",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                smsSendFlag: {
                  name: "SMS전송여부",
                  type: "boolean",
                  methods: ["create", "update", "delete"],
                },
                smsSendCnt: {
                  name: "SMS전송횟수",
                  type: "number",
                  methods: ["create", "update", "delete"],
                },
                smsSendDt: {
                  name: "SMS전송일시",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
              }
            }
          });
        });
      });
    },
    historyPopupClosed() {
      this.isHistoryPopupOpen = false;
    },
    async saveBlackinfo() {
      if (!this.validate()) {
        return;
      }
      if (
        this.blacklistInfo.penltyKind === "GRP" &&
        !this.blacklistInfo.grpNo
      ) {
        this.errorToast(
          "위약종류가 단체팀인 경우에는 단체명을 입력해 주서야 합니다"
        );
        return;
      }
      if (this.blacklistInfo.penltyId) {
        await putBlackinfo(this.blacklistInfo);
        this.$emit("refresh");
        this.popupClose();
      } else {
        try {
          if (
            (this.blacklistInfo.membershipId || this.blacklistInfo.contactTel) &&
            this.blacklistInfo.penltyLmttScope !== "NA"
          ) {
            const checkCount = await GolfErpAPI.fetchAdditionalRegistrationCheck({
              occurDate: this.blacklistInfo.occurDate,
              bsnCode: this.blacklistInfo.bsnCode,
              penltyCode: this.blacklistInfo.penltyCode,
              penltyLmttScope: this.blacklistInfo.penltyLmttScope,
              membershipId: this.blacklistInfo.membershipId,
              contactTel: this.blacklistInfo.membershipId ? null : this.blacklistInfo.contactTel
            });

            if (checkCount > 0) {
              if (
                !(await this.confirm(
                  this.blacklistInfo.memberName + " 님 " + this.blacklistInfo.occurDate +
                  ' "' + commonCodesGetComName("PENLTY_CODE", this.blacklistInfo.penltyCode) + '" ' +
                  this.blacklistInfo.penltyDayNum + "일 " +
                  commonCodesGetComName("PENLTY_LMTT_SCOPE", this.blacklistInfo.penltyLmttScope) + " 위약건이 존재합니다.<br>" +
                  "추가 등록하시겠습니까?"
                ))
              ) {
                return;
              }
            }
          }

          await postBlackinfo(this.blacklistInfo);
          this.$emit("refresh");
          this.popupClose();
        } catch (e) {
          console.error(`saveBlackinfo.err.===>`, e);
        }
      }
      this.infoToast(this.$t("main.popupMessage.saved"));
    },
    popupClose() {
      this.$refs.blacklistPopup.hide();
    },
    onChangeBsnCode(args) {
      if (args.isInteracted) {
        this.getBlackEnddate();
      }
    },
    onPenltyCodeChanged(args) {
      if (args.isInteracted) {
        this.getBlackEnddate();
      }
    },
    onPenltyDivChanged(args) {
      if (args.isInteracted) {
        this.getBlackEnddate();
      }
    },
    async getBlackEnddate() {
      if (this.blacklistInfo.penltyCode) {
        if (
          this.blacklistInfo.bsnCode &&
          this.blacklistInfo.penltyCode &&
          this.blacklistInfo.penltyDiv
        ) {
          // penltyCode의 jsonData 항목 중 IDX = 1인 항목이 있어야만 조회한다
          const params = {
            bsnCode: this.blacklistInfo.bsnCode,
            penltyCode: this.blacklistInfo.penltyCode,
            penltyDiv: this.blacklistInfo.penltyDiv,
            punshStartDate: this.blacklistInfo.punshStartDate,
          };
          const { value } = await getBlackEnddate(params);

          this.blacklistInfo.penltyDayNum = value.penltyDayCount;
          this.blacklistInfo.penltyDem = value.penltyDem;
          this.blacklistInfo.penltyLmttScope = value.penltyLmttScope;
          this.blacklistInfo.penltyAmt = value.penltyAmt;
          this.blacklistInfo.ddctMethod = value.ddctMethod;
          this.blacklistInfo.ddctScore = value.ddctScore;

          if (value.punshEndDate) {
            this.blacklistInfo.punshEndDate = value.punshEndDate;
          }
        }
      }
    },
    /**
     * validate 실행 함수
     * @return {boolean}
     */
    validate() {
      return this.validateFormRefs(this.validateRefList);
    },
    memberNameInputKeyEnter() {
      if (this.blacklistInfo.memberName.trim() === "") {
        // 빈 칸인 경우에는 미 설정으로
        this.setNoMember();
        return;
      }

      // 회원정보에 없는 비회원이라도 무조건 회원 검색 팝업 호출. 모든 화면 공통 조건.
      this.onMemberPopupOpen(false, this.blacklistInfo.memberName);
    },
    onMemberDivChanged(args) {
      if (
        args.e instanceof MouseEvent ||
        args.e instanceof KeyboardEvent ||
        args.e instanceof TouchEvent
      ) {
        if (args.value !== "NOM") {
          // 회원구분이 비회원이 아니면 동명이인 회원 검색 팝업 호출.
          this.onMemberPopupOpen(false, this.blacklistInfo.memberName, true);
        } else {
          this.setNoMember();
        }
      }
    },
    onMemberGradeChanged(args) {
      if (
        args.e instanceof MouseEvent ||
        args.e instanceof KeyboardEvent ||
        args.e instanceof TouchEvent
      ) {
        if (args.value !== "NOR") {
          // 회원등급이 비회원이 아니면 동명이인 회원 검색 팝업 호출.
          this.onMemberPopupOpen(false, this.blacklistInfo.memberName, false, true);
        } else {
          this.setNoMember();
        }
      }
    },
  },
};
</script>
